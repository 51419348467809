<template>
  <div class="container-fluid">
    <nav
      class="container-fluid navbar navbar-expand-lg navbar-light bg-light"
      id="my-filter"
    >
      <div class="row w-100 text-right" v-if="screenSize.width < 992">
        <h6 class="col-auto mt-auto">تصفية النتائج</h6>
        <button
          class="navbar-toggler col-auto"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style="border: none"
        >
          <i class="filter icon"></i>
        </button>
      </div>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <form @submit.prevent class="form-inline my-2 my-lg-0">
          <div
            v-for="filter in fillters"
            :class="{
              'col-lg-4': filter.label == 'السنة',
              'col-lg-3': filter.label != 'السنة',
            }"
            class="col-sm-12 mt-2"
            :dir="'rtl'"
          >
            <div v-if="filter.label == 'السنة'">
              <div class="input-group" style="direction: ltr">
                <input
                  type="number"
                  placeholder="إلى"
                  class="form-control text-center"
                  minlength="4"
                  min="1900"
                  max="2030"
                  v-model="filter.value[0]"
                />
                <input
                  type="number"
                  placeholder="من"
                  class="form-control text-center"
                  minlength="4"
                  min="1900"
                  max="2030"
                  v-model="filter.value[1]"
                />

                <div class="input-group-prepend">
                  <span class="input-group-text">السنة</span>
                </div>
              </div>
            </div>
            <Treeselect
              v-else
              v-model="filter.value"
              :multiple="true"
              :options="filter.options"
              :placeholder="filter.label"
            />
          </div>
          <button
            class="btn btn-success mt-2"
            @click="runFilter"
            style="margin-right: 15px"
          >
            تطبيق
          </button>
          <button
            type="button"
            class="btn btn-danger mt-2 mr-2"
            @click="clearFilter"
          >
            إعادة تعيين
          </button>
        </form>
      </div>
    </nav>
  </div>
</template>

<script>
// import the component
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

$("#navbarSupportedContent").on("show.navbar-collapse.collapse", function () {
  $("#my-filter").addClass("our-nav");
});
$(".filter:has(.years-fillter)").addClass("col-lg-4");
export default {
  data() {
    return {};
  },
  props: {
    cardFillters: {
      type: Array,
    },
  },
  components: {
    Treeselect,
  },
  methods: {
    runFilter() {
      this.$emit("filterItems");
    },
    clearFilter() {
      this.$emit("clearFilter");
    },
  },
  computed: {
    fillters: {
      get() {
        return this.cardFillters;
      },
      set(newValue) {
        console.log(newValue);
      },
    },
    screenSize() {
      return this.$store.getters["glopal/window"];
    },
  },
};
</script>

<style scoped>
.our-nav {
  background: linear-gradient(
    to bottom,
    rgba(140, 140, 140, 0.271) 0%,
    rgba(140, 140, 140, 0.271) 25%,
    #f4f5f8 25%,
    #f4f5f8 100%
  ) !important;
}

.vue-treeselect__label {
  float: right !important;
}
input:focus::placeholder {
  color: transparent;
}

@media only screen and (min-width: 992px) {
  .col-lg-3 {
    max-width: 26% !important;
    flex-basis: 24%;
  }
  .col-lg-4 {
    max-width: 32% !important;
    flex-basis: 32%;
  }
}
</style>
