<template>
  <div class="container d-flex justify-content-center" v-if="noPage > 1">
    <nav aria-label="Page navigation example">
      <ul class="pagination nav">
        <li class="page-item" :class="{ disabled: paginationInfo.currentPage <= 1 }">
          <a class="page-link" @click="changePage(paginationInfo.currentPage - 1)">السابق</a>
        </li>

        <li
          class="page-item"
          v-for="i in noPage"
          :class="{ active: i ===  paginationInfo.currentPage }"
        >
          <span class="page-link" @click="changePage(i)">{{ i }}</span>
        </li>

        <li class="page-item" :class="{ disabled: noPage <=  paginationInfo.currentPage }">
          <a class="page-link" @click="changePage( paginationInfo.currentPage + 1)">التالي</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
      
    };
  },
  props: {
    paginationInfo: {},
  },
  computed: {
    noPage() {
      return Math.floor(
        (this.paginationInfo.rowsLength + this.paginationInfo.limit - 1) /
          this.paginationInfo.limit
      );
    },
  },
  methods: {
    changePage(index) {
      if ( this.paginationInfo.currentPage == index) {

        return;
      }

      this.paginationInfo.currentPage= index;
      this.paginationInfo.currentPage = index;
      console.log( this.paginationInfo.currentPage);
      this.$emit("changePage", index);
      return;
    },
    changeActivePage(index) {

       this.paginationInfo.currentPage = index;
      return;
    },
  },
};
</script>

<style scoped>
.page-item:first-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: rgba(9, 88, 137, 1);
  border-color: rgba(9, 88, 137, 1);
}

.nav {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
.nav li {
  display: inline;
  cursor: pointer;
}
.nav a {
  display: inline-block;
}
</style>
