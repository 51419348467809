<template>
  <div class="container-fluid cardComponent">
    <cardFilter v-if="this.$store.getters['glopal/UserBrowser'] != 'firefox' && fillters" :cardFillters="fillters"
      @clearFilter="clearFilter" @filterItems="filterItems">
    </cardFilter>
    <div v-if="rows.length == 0" class="text-center my-4">
      <h1>لا توجد نتائج</h1>
    </div>
    <div class="row d-flex justify-content-around mt-2 px-2">
      <div class="col-lg-3 col-md-5 col-sm-10 my-1 p-0 text-center scrapiz-card rounded" v-for="(row, index) in rows"
        :key="index" style="max-width: 30rem">

        <div class=" scrapiz-card-header" v-for="slot in cardSlots.headerSlots">

          <slot :name="slot" :row="row" :index="paginationInfo.index + index + 1" v-if="paginationInfo"></slot>
          <slot :name="slot" :row="row" :index="index + 1" v-else></slot>

        </div>

        <div class="scrapiz-card-body" ref="mySlot">
          <div v-for="slot in cardSlots.topSlots">

            <slot :name="slot" :row="row"></slot>
            <div class="dropdown-divider" v-if="haveSlot(slot)"></div>
          </div>
          <div v-for="(v, i, count) in columns">
            <div class="row justify-content-around">
              <p class="col-5 m-auto">
                <slot>{{ v.label }}</slot>
              </p>
              <p class="col-5 m-auto">
                <slot>{{ save_object(row, v.key) }}</slot>
              </p>
            </div>
            <div class="dropdown-divider" v-show="i < Object.keys(columns).length - 1"></div>
          </div>
          <div v-for="slot in cardSlots.bottomSlots">
            <div class="dropdown-divider" v-if="haveSlot(slot)"></div>
            <slot :name="slot" :row="row"></slot>
          </div>
        </div>

      </div>
    </div>
    <div class="row my-3">
      <pagination v-if="paginationInfo && paginationInfo.rowsLength > 0" :paginationInfo="paginationInfo"
        @changePage="changePage">
      </pagination>
    </div>
  </div>
</template>

<script>
import pagination from "../cards/Pagination.vue";
import typy from "typy";
import fillter from "./filter.vue";
export default {
  data() {
    return {
      //here you can add more slots
      //  cardSlots: ["lowestOffer", "counter", "actions"],
    };
  },
  props: {
    columns: {},
    rows: {},
    rowsLength: 0,
    paginationInfo: {},
    cardFillters: {
      type: Array,
    },
    cardSlots: {}
  },
  components: {
    pagination,
    cardFilter: fillter,
  },
  methods: {
    haveSlot(slot) {
      return this.$scopedSlots[slot];
    },
    save_object(object, nested) {
      return typy(object, nested).safeObject;
    },
    changePage(i) {
      console.log(i);
      this.$emit("changePage", i);
      return;
    },
    filterItems() {
      this.$emit("filterItems");
    },
    clearFilter() {
      this.$emit("clearFilter");
    },
  },
  computed: {
    fillters() {
      return this.cardFillters;
    },
  },
};
</script>

<style>
.scrapiz-card {
  background-color: rgba(140, 140, 140, 0.271) !important;
  flex: 1 0 auto;
}


.scrapiz-card-header {
  width: 100% !important;
  display: block;
  color: #606266;
  word-wrap: break-word;
  text-align: center;
  background: linear-gradient(#f4f5f8, #f1f3f6);
  padding-top: 0.2rem;
}

.scrapiz-card-body .row {
  padding: 0.5em 0.5em 0.5em 0.5em;
  color: #606266;
}

.scrapiz-card-body .row p {
  font-weight: 700;
}

.container .cardComponent {
  padding: 0;
}

/*
.row>div[class*="col-"] {
  flex: 1 0 auto;
}
*/

@media only screen and (min-width: 992px) {
  .col-lg-3 {
    max-width: 32%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-md-5 {
    max-width: 48%;
  }
}
</style>
